<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h6 class="mb-2">
              Create/Edit Your Ad
            </h6>
            <hr>
            <div class="container">
              <div class="row">
                <div class="col-md-5">
                    <input
                      id="validatedCustomFile"
                      type="file"
                      class="custom-file-input"
                      @change="onFileChange"
                      ref="file"
                      name="image"
                      accept="image/*"
                      required
                      style="cursor:pointer"
                    >
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                    >Choose file...</label>
                </div>
                  <div class="col-md-6 "></div>
                <div class="col-md-4 mt-3 text-center">
                  <img style="width:150px;height:130px;" :src="url" alt="">
                </div>
              </div>
              <div class="row">
                <p class="font-weight-bold"> Ad Will Link To ? </p>
              </div>
               <div class="row">
                 <div class="col-md-12">
                    <span>
                     <input @click="setOneLink" v-model="ad_link" type="checkbox" id="unique_link" placeholder="https://www."/> &nbsp; <label for="unique_link"> This Link for All Locations</label>
                       &nbsp; <input   @change="handleChange" style="width:40%;display:inline" type="text" class="form-control" placeholder="https://www."/>
                    </span>
                 </div>
              </div>
              <br>
               <div class="row">
                 <div class="col-md-12">
                    <span>
                      <input type="checkbox" id="place_link" @click="setUniqueLink" v-model="unique_link" > &nbsp;
                      <label for="place_link"> Unique Link - Link You Add for Each Added Location </label>
                    </span>
                 </div>
              </div>
              <br>
              <div class="row">
                <p class="font-weight-bold mt-2"> To Update an already purchased ad</p>  &nbsp;&nbsp;
                <button class="btn btn-warning btn-sm"> Update &nbsp; <i class="fa fa-refresh" aria-hidden="true"></i> </button>
              </div>
              <br>
              <div class="row">
                <div class=col-md-6>
                  <router-link to="/purchase-child-ad" class="btn btn-success mt-3"><i class="fas fa-arrow-left"></i> Back</router-link>
                </div>
                <div class=col-md-6>
                  <router-link to="/child-checkout" class="btn btn-primary mt-3" style="float:right"> Next <i class="fas fa-arrow-right"></i></router-link>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../../services/http-common';
export default {
  data(){
    return {
        url: '/static/images/ad-placeholder.png',
        ad_link:false,
        unique_link:false,
    }
  },
  methods:{
     totalLocations(){
            return axios
                .get(`${process.env.VUE_APP_API_URL}total_locations`)
                .then(response => {
                this.total_locations = response.data;
            }).catch(error => {
                this.$toast.error('Something Went Wrong!', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
            });
        },
    handleChange(e){
      let val = e.target.value;
      localStorage.setItem('ad_link' , val); 
    },
    setUniqueLink(){
      localStorage.setItem('child_unique_link',!this.unique_link);
    },
    setOneLink(){
      localStorage.setItem('child_ad_link',!this.ad_link);
    },
  
     onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          var base64String = reader.result;
          localStorage.setItem('child_ad_image' , base64String); 
        }
    }   
  },
  mounted(){
    this.totalLocations();
       
  }
}
</script>
<style scoped>
.dashboard-list-box ul li{
    padding: 13px 30px !important;
}
.uppercase{
    text-transform: uppercase !important;
}
</style>
